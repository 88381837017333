#booking-timeline {
  &>li {
    .ant-timeline-item-label {
      width: calc(20% - 12px);
    }
    .ant-timeline-item-tail {
      left: 20%;
    }
    .ant-timeline-item-head {
      left: 20%;
    }
    .ant-timeline-item-content {
      left: calc(20% - 4px);
      width: calc(80% - 14px);
    }
  }

  .coupon-code {
    font-weight: bold;
    color: #1890ff;
  }
  .point-get {
    font-weight: bold;
    color: #e29b00;
  }
  u {
    text-underline-offset: 0.2em;
  }
}
