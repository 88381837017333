.ant-layout-header {
  padding: 0;
}

.ant-menu-submenu-title {
  a {
    color: rgba(0, 0, 0, 0.65);
  }
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-layout-sider {
  background-color: #fff;
}
