@import "./constants.scss";

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-wrapper {
  min-height: 100vh;
  width: 100%;
}

.page-container {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  padding: 16px 24px;
  margin: 0;
}

* {
  box-sizing: border-box;
}

.ant-row {
  width: 100%;
}

.ant-legacy-form-item {
  width: fit-content;
}

// .ant-form-item {
//   width: fit-content;
// }

.ant-table-small {
  border: 0;
}

th.column-money,
td.column-money {
  text-align: right !important;
}

.search-section {
  display: flex;
  align-items: center;

  & > main {
    width: auto;
  }

  .stat {
    padding: 8px 16px;
    background-color: #ececec;
    margin-bottom: auto;
  }
}

.styled-transparent {
  background-color: transparent;
}

.support-for-web-block {
  .search-section {
    justify-content: flex-end;
  }
}

.edit-question-form {
  .ant-legacy-form-item-required {
    color: #ff4d4f;

    &::before {
      font-weight: bold;
      font-family: sans-serif;
    }
  }

  .ant-legacy-form-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;
  }

  .ant-legacy-form-item-label {
    text-align: left;
    font-weight: bold;
  }
}

.flex {
  display: flex;
  &.justify-between {
    justify-content: space-between;
  }
}
